import React from "react";
import data from '../../../../queries/data/apps/sky-kids';
import Layout from "../../../UI/Layout";
import '../../../scss/packages.scss';
import SingleCarouselSlide from "../../../components/editorial/SingleCarouselSlide";
import useLS from "../../../hooks/useLS";

const Index = () => {

  const carousel = {
    mainCarousel : [
      ...data.data.wp.app.options
    ]
  }

  const dataHeader = data.data.wp.app.dataHeader;
  const links = data.data.wp.app.links;

  const LSData = useLS();

  return (
    <Layout className="Editorial skyGo app" dataHeader={dataHeader}>
      <SingleCarouselSlide
      slideType="appSlides"
      storeID={LSData.storeID}
      links={links}
      info={carousel}/>
    </Layout>
  )
}

export default Index;